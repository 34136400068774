<template>
  <div class="page-container">
    <router-view class="tutorial"></router-view>
  </div>
</template>

<script>
export default {
  name: 'tutorial',
}
</script>

<style lang="scss">
#app {
  height: 100%;
}
</style>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.page-container {
  background-color: #282729;
  overflow-y: auto;
  height: 100%;

  .tutorial {
    margin: 0 auto;
    width: 62.5%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    padding-top: 8.75rem;
    padding-bottom: 10rem;
    color: white;
    font-size: 1rem;

    @include md {
      width: 100%;
      padding: 1rem 2rem 3rem 2rem;
      font-size: 0.8rem;
    }

    ::v-deep {
      > .page-title {
        color: #deaf6b;
        font-size: 1.6rem;
        line-height: 1.4;

        @include md {
          font-size: 1.2rem;
        }
      }

      > .rows {
        display: flex;
        flex-direction: column;
        margin-top: 1.2rem;
        text-align: left;
        line-height: 1.2;

        > video {
          max-width: 800px;
          margin: 0 auto;

          @include md {
            margin: unset;
          }
        }

        &.faq {
          padding: 0 0.5rem;
        }

        > img {
          width: 100%;
          margin: 0.7rem auto 0.5rem;

          &.snap {
            width: 66.7%;
            max-width: 800px;

            @include md {
              width: 100%;
            }
          }

          &.qrcode {
            width: 500px;
            height: 500px;

            @include md {
              width: 200px;
              height: 200px;
            }
          }
        }

        p {
          margin-bottom: 1rem;
          font-weight: 300;

          &.title {
            color: #15c7ee;
            font-size: 1.2rem;
            margin-bottom: 1rem;
            font-weight: 400;
          }

          &.subtitle {
            font-weight: 500;
            margin-top: 1rem;
            margin-bottom: 0.1rem;
          }

          span.lightgreen {
            color: #66c4b6;
          }

          a {
            color: #088ade;
            text-decoration: underline;
            text-underline-offset: 4px;
            word-break: break-all;

            &.faq-type {
              color: #f0c94d;
              font-size: 0.9rem;
              text-underline-offset: 2px;
            }
          }

          &.faq-type {
            color: #f0c94d;
            font-size: 0.9rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
